import React, { useContext } from 'react';
import { Box, Button, Card, CardActionArea, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';
import { UIContext } from '../../context/UIContext';

const TransferOption = ({
  transfer,
  isSelected,
  changeTransferOption,
  userCountry,
}: {
  transfer: {
    payoutDelayDays: number;
    type: string;
  };
  isSelected: boolean;
  changeTransferOption: (_val: number) => void;
  userCountry: string;
}) => {
  const { t } = useTranslation();
  const { colorMode } = useContext(UIContext);

  const getPlatformSuffix = () => {
    const countryCode = userCountry.toUpperCase();
    if (countryCode === 'IE' || countryCode === 'ES')
      return 'EU';
    if (countryCode === 'NZ')
      return 'NZ'

    return '';
  }

  const getPayoutPlanDescription = () => {
    const countryCode = userCountry.toUpperCase();
    if (countryCode === 'IE' || countryCode === 'ES') {
      return (
        <>
          <Typography
            sx={{
              color: (theme) =>
                isSelected ? theme.palette.text.primary : theme.palette.text.secondary,
            }}
          >
            {t(`screens.verification.plans.${transfer.type}EU.description`)}
          </Typography>
          <Typography
            sx={{
              color: (theme) =>
                isSelected ? theme.palette.text.primary : theme.palette.text.secondary,
              marginY: 2,
            }}
          >
            {t(`screens.verification.plans.${transfer.type}EU.description-2`)}
          </Typography>
        </>
      )
    }

    if (countryCode === 'NZ') {
      return (
        <>
          <Typography
            sx={{
              color: (theme) =>
                isSelected ? theme.palette.text.primary : theme.palette.text.secondary,
            }}
          >
            {t(`screens.verification.plans.${transfer.type}NZ.description`)}
          </Typography>
          <Typography
            sx={{
              color: (theme) =>
                isSelected ? theme.palette.text.primary : theme.palette.text.secondary,
               marginY: 2,
            }}
          >
            {t(`screens.verification.plans.${transfer.type}NZ.description-2`)}
          </Typography>
          <Typography
            sx={{
              color: (theme) =>
                isSelected ? theme.palette.text.primary : theme.palette.text.secondary,
              marginY: 2,
            }}
          >
            {t(`screens.verification.plans.${transfer.type}NZ.description-3`)}
          </Typography>
        </>
      )
    }

    return (
      <Typography
        sx={{
          color: (theme) =>
            isSelected ? theme.palette.text.primary : theme.palette.text.secondary,
        }}
      >
        {t(`screens.verification.plans.${transfer.type}.description`)}
      </Typography>);
  }

  return (
    <Grid item xs={12} sm={6} display="flex">
      <Card
        elevation={0}
        sx={{
          border: '1px solid',
          ...(isSelected && {
            borderColor: (theme) => `${theme.palette.primary.main}`,
          }),
          ...(!isSelected && {
            borderColor: (theme) =>
              `${colorMode === 'light' ? theme.palette.grey['12p'] : theme.palette.grey['23p']}`,
          }),
          ...(isSelected && {
            backgroundColor: '#013BAE0A',
          }),
        }}
      >
        <CardActionArea
          sx={{
            p: { xs: 2, sm: 3 },
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
          component={Button}
          onClick={() => changeTransferOption(transfer?.payoutDelayDays)}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              textAlign: 'left',
            }}
          >
            <Typography variant="h3" sx={{ fontSize: '18px' }} marginBottom={1}>
              {t(`screens.verification.plans.${transfer.type}${getPlatformSuffix()}.title`)}
            </Typography>
            {getPayoutPlanDescription()}
          </Box>
          <Box
            marginTop={2}
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              width: '100%',
              height: '2em',
            }}
          >
            {isSelected ? (
              <CheckCircleRoundedIcon color="primary" sx={{ fontSize: '32px' }} />
            ) : (
              <RadioButtonUncheckedRoundedIcon
                sx={{
                  fontSize: '32px',
                  color: (theme) => theme.palette.text.secondary,
                }}
              />
            )}
            <Typography
              sx={{
                fontWeight: 600,
                marginLeft: '16px',
                color: (theme) =>
                  isSelected ? theme.palette.text.primary : theme.palette.text.secondary,
              }}
            >
              {t(`screens.verification.plans.${transfer.type}${getPlatformSuffix()}.cost`)}
            </Typography>
          </Box>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default TransferOption;
